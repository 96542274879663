import { StarIcon } from "@heroicons/react/20/solid";
function Reviews() {
  let reviews = [
    {
      text: "I use Shopdeploy everyday to reprice my Takealot listings, and it's boosted my business by 30%. Highly recommended!",
      author: "Quentin",
      company: "Home & Appliances Seller",
    },
    {
      text: "You cannot be successful on Takealot Marketplace without Shopdeploy",
      author: "Jaco",
      company: "Electronics Seller",
    },
    {
      text: "Shopdeploy's repricing tools are the best in the market. I've tried them all and nothing comes close.",
      author: "Muhammad",
      company: "Health & Beauty Seller",
    },
  ];

  return (
    <div className="py-10 sm:py-15 ">
      <div className="mx-auto max-w-7xl text-center flex flex-col gap-2 sm:gap-10 ">
        <div className="font-bold text-2xl tracking-snug  text-slate-800 px-4">
          Trusted by <span className="text-orange-600">350+</span> ecommerce
          businesses in South Africa.
        </div>

        <div className="flex w-full divide-y sm:divide-y-0 sm:divide-x divide-dotted divide-gray-200 flex-col sm:flex-row">
          {reviews.map((review, index) => {
            return (
              <div className="sm:w-1/2 md:w-1/3 " key={index}>
                <div className="p-6 flex flex-col gap-5 items ">
                  <div className="flex gap-x-1 text-orange-600 justify-center">
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        className="h-6 w-6 flex-none"
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <q className="flex-grow text-slate-800 font-normal text-lg sm:text-xl leading-7 text-balance ">
                    {review.text}
                  </q>
                  <div className="hidden sm:block">
                    <div className="text-xl text-gray-700 font-medium ">
                      {review.author}
                    </div>
                    <div className="text-base text-gray-500 ">
                      {review.company}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export { Reviews };
