import {
  Activity,
  BarChart,
  Check,
  Database,
  DollarSign,
  Package,
  Search,
  Share2,
  Smartphone,
  Table,
  Upload,
  Users,
  Zap,
} from "react-feather";

function Features() {
  const features2 = [
    {
      title: "Automated Repricing",
      description:
        "Automatically adjust your prices to stay competitive and increase your chances of winning the Buy Box on Takealot.",
      bullets: [
        "Set minimum and maximum price thresholds.",
        "Customize alerts for price adjustments.",
      ],
      icon: Zap,
      colour: "blue",
    },
    {
      title: "Buy Box Monitoring",
      description:
        "Receive instant alerts if you no longer own the Buy Box, helping you respond swiftly to changes.",
      bullets: [
        "Continuous monitoring across all your products.",
        "Receive detailed notifications via email when your product status changes.",
      ],
      icon: Activity,
      colour: "teal",
    },
    {
      title: "Order-Level Profitability",
      description:
        "Gain precise visibility into the profitability of each order. Evaluate profit margins in real-time.",
      bullets: [
        "Calculate net profit per order after accounting for all costs",
      ],
      icon: DollarSign,
      colour: "green",
    },
    {
      title: "Multiple Seller Accounts",
      description:
        "Streamline management of multiple Takealot seller accounts through a unified dashboard.",
      bullets: [
        "Switch effortlessly between accounts.",
        "Aggregate views of all accounts for simplified management.",
      ],
      icon: Database,
      colour: "orange",
    },
    {
      title: "Filtering & Search",
      description:
        "Quickly locate specific products with advanced search and filtering options, saving you time and effort.",
      bullets: [
        "Utilize powerful filters to narrow down search results.",
        "Speed up product searches with an intuitive interface.",
      ],
      icon: Search,
      colour: "purple",
    },
    {
      title: "Import from .CSV",
      description:
        "Streamline your product management by importing details from a .CSV file for bulk updates.",
      bullets: [
        "Bulk manage pricing adjustments.",
        "Update product costs efficiently.",
      ],
      icon: Upload,
      colour: "pink",
    },
    {
      title: "Multiple Users",
      description:
        "Facilitate collaboration by allowing multiple team members to access and manage your Shopdeploy account.",
      bullets: [
        "Easily add and manage user permissions.",
        "Enhance team productivity with collaborative tools.",
      ],
      icon: Users,
      colour: "fuchsia",
    },
    {
      title: "Export to .CSV",
      description:
        "Export essential data such as sales, product details, and repricing information to a .CSV file for comprehensive analysis.",
      bullets: [
        "Extract detailed sales reports.",
        "Gather comprehensive product and pricing data for offline analysis.",
      ],
      icon: Table,
      colour: "cyan",
    },
    {
      title: "Sales Analytics",
      description:
        "Gain deep insights into your sales trends and performance with our robust analytics tools.",
      bullets: [
        // "Analyze revenue changes over different periods.",
        "Identify and capitalize on emerging sales trends.",
        "Access a comprehensive history of your sales data.",
      ],
      icon: BarChart,
      colour: "green",
    },
    {
      title: "Mobile Web App",
      description:
        "Access Shopdeploy anywhere, anytime with our responsive mobile web application, compatible with Android and iOS.",
      bullets: [
        "Manage sales and repricing on the go.",
        "Comprehensive mobile functionality for active business management.",
      ],
      icon: Smartphone,
      colour: "amber",
    },
    {
      title: "Webhook Integration",
      description:
        "Keep your Shopdeploy data synchronized with Takealot using our efficient webhook integration.",
      bullets: [
        "Receive instant updates directly from Takealot.",
        "Automatically update prices and stock levels to reflect real-time changes.",
      ],
      icon: Share2,
      colour: "red",
    },
    {
      title: "Inventory Optimization",
      description:
        "Maximize your inventory efficiency with predictive analytics that help maintain optimal stock levels.",
      bullets: [
        "Analyze sales patterns to predict future inventory needs.",
        "Receive alerts when stock levels are low or adjustments are needed.",
      ],
      icon: Package,
      colour: "teal",
    },
  ];

  const colorVariants = {
    blue: "bg-blue-200 text-blue-700 shadow-blue-100",
    red: "bg-red-200 text-red-700 shadow-red-100",
    teal: "bg-teal-200 text-teal-700 shadow-teal-100",
    rose: "bg-rose-200 text-rose-700 shadow-rose-100",
    cyan: "bg-cyan-200 text-cyan-700 shadow-cyan-100",
    green: "bg-green-200 text-green-700 shadow-green-100",
    fuchsia: "bg-fuchsia-200 text-fuchsia-700 shadow-fuchsia-100",
    pink: "bg-pink-200 text-pink-700 shadow-pink-100",
    purple: "bg-purple-200 text-purple-700 shadow-purple-100",
    orange: "bg-orange-200 text-orange-700 shadow-orange-100",
    lime: "bg-lime-200 text-lime-700 shadow-lime-100",
    yellow: "bg-yellow-200 text-yellow-700 shadow-yellow-100",
    amber: "bg-amber-200 text-amber-700 shadow-amber-100",
  };

  const checkColours = {
    blue: "text-blue-600",
    red: "text-red-600",
    teal: "text-teal-600",
    rose: "text-rose-600",
    cyan: "text-cyan-600",
    green: "text-green-600",
    fuchsia: "text-fuchsia-600",
    pink: "text-pink-600",
    purple: "text-purple-600",
    orange: "text-orange-600",
    lime: "text-lime-600",
    yellow: "text-yellow-600",
    amber: "text-amber-600",
  };

  return (
    <div>
      <div className="bg-stone-50 ">
        <div className="md:px-10 py-10 md:py-20 mx-auto max-w-7xl px-4 sm:px-6 lg:px-18 ">
          <div className="text-center text-pretty">
            <p className="leading-none font-bold uppercase text-purple-700 pb-3">
              Features
            </p>
            <h2 className="text-3xl md:text-5xl font-bold text-slate-800 leading-tight mb-8 md:mb-20 ">
              All the tools you need to grow your marketplace business
            </h2>
          </div>

          <div className="grid grid-flow-row-dense sm:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-7 text-left mt-2 ">
            {features2.map((feature) => {
              return (
                <div
                  key={feature.title}
                  className={`bg-white flex transform origin-center hover:scale-105 border  transition duration-150 ease-in-out flex-col justify-between sm:flex-row rounded-xl p-6 sm:p-8 ${
                    feature.img && "sm:col-span-2"
                  }`}
                >
                  <div>
                    <div className=" mb-5">
                      <div
                        className={`flex h-8 w-8 sm:h-10 sm:w-10 p-1 shrink-0 grow-0 items-center justify-center rounded-full  after:content-[""] after:absolute after:inset-0 outline-none  shadow-[0_0_0_7px] ${
                          colorVariants[feature.colour]
                        }`}
                      >
                        {feature.icon && (
                          <feature.icon size={25} strokeWidth={1.5} />
                        )}
                      </div>
                    </div>
                    <div className="pb-1 md:pb-2 text-slate-900 font-semibold text-lg md:text-2xl leading-6">
                      {feature.title}
                    </div>
                    <div className="text-slate-700 text-base font-normal">
                      <p className="leading-normal">{feature.description}</p>
                    </div>
                    {feature.bullets && (
                      <ul className="mt-5 text-slate-600 font-normal">
                        {feature.bullets.map((bullet) => {
                          return (
                            <li
                              key={bullet}
                              className="flex gap-x-2 leading-tight mb-2"
                            >
                              <span className={checkColours[feature.colour]}>
                                <Check size={22} strokeWidth={2.5} />
                              </span>
                              {bullet}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export { Features };
