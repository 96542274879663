import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { Features } from "~/components/features";
import { Image } from "~/components/image";
import { Reviews } from "~/components/reviews";
import { Testimonials } from "~/components/testimonials";
import { useResponsiveImage } from "~/hook/responsiveImage";
import homepageHero from "/data/images/homepage-hero-1.png";
import ogImage from "/data/images/shopdeploy-og-image.png";

const responsive = [
  {
    size: {
      width: 1440,
    },
  },
  {
    size: {
      width: 1216,
    },
  },
  {
    size: {
      width: 1024,
    },
  },
  {
    size: {
      width: 820,
    },
  },
  {
    size: {
      width: 540,
    },
  },
  {
    size: {
      width: 320,
    },
  },
];
const options = { quality: 50 };
const sizes = "(min-width: 1360px) 1216px, 91.15vw";

export const meta: MetaFunction = () => {
  return [
    { title: "Shopdeploy: Takealot Repricer, Analytics & Buy Box Tools" },
    {
      name: "description",
      content:
        "Shopdeploy helps hundreds of sellers grow their ecommerce businesses on Takealot.",
    },
    {
      property: "og:title",
      content: "Shopdeploy - Takealot Repricer, Analytics & Buy Box Tools",
    },
    { property: "og:image", content: ogImage },
    {
      property: "og:description",
      content:
        "Grow your ecommerce marketplace business with repricing from Shopdeploy",
    },
    { property: "og:url", content: "https://www.shopdeploy.com" },
    { property: "og:type", content: "website" },
  ];
};

export const links = () => {
  const { src, srcSet } = useResponsiveImage(homepageHero, responsive, options);
  const link = {
    rel: "preload",
    href: src,
    imageSrcSet: srcSet,
    imageSizes: sizes,
    as: "image",
  };
  return [link];
};

export default function Index() {
  return (
    <div>
      <div className="bg-indigo-50 antialiased">
        <div className="mx-auto max-w-7xl pt-10 md:pt-14 px-4 sm:px-6 lg:px-8 text-center ">
          <h1 className="font-bold text-4xl sm:text-5xl mb-4 text-slate-800">
            Build and Grow your Takealot Business
          </h1>
          <div className="mt-3 text-lg font-normal text-indigo-900">
            Grow your sales and profits with our Automated Repricing, Unlimited
            Buy Box Monitoring and Powerful Sales Analytics suite.
          </div>

          <div className="flex justify-center mt-8 sm:mt-12">
            <Link
              to="https://app.shopdeploy.com/signup"
              className="w-full sm:max-w-xs text-white bg-orange-600 hover:bg-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-300 font-medium rounded-md text-2xl px-7 py-3.5 text-center"
            >
              Try it free
            </Link>
          </div>
          <div className="font-light text-xs my-5 text-slate-900">
            Full access. No credit card required. Used by over 350 businesses.
          </div>

          <Image
            src={homepageHero}
            alt="Shopdeploy homepage hero image"
            responsive={responsive}
            options={options}
            height={502}
            width={1216}
            className="pt-5"
            definedSizes={sizes}
            style={{ maxWidth: "100%", height: "auto" }}
            fetchpriority="high"
          />
        </div>
      </div>
      <Reviews />
      <Features />
      <Testimonials />
    </div>
  );
}
