import { useEffect, useRef } from "react";
import { useEventListener } from "usehooks-ts";

const reviews = [
  {
    author: "Olivier",
    initials: "OC",
    company: "Garden & DIY Seller",
    text: "Shopdeploy is the the only practical tool that calculates profitability at the order level.",
    colour: "purple",
  },
  {
    author: "Bilal",
    initials: "BM",
    company: "Home & Appliances Seller",
    text: "The user-friendly layout of Shopdeploy is very easy to understand and get the hang of.",
    colour: "pink",
  },
  {
    author: "Irfan",
    initials: "I",
    company: "Health & Beauty Seller",
    text: "I used to spend so much time performing manual tasks. Now with Shopdeploy no more downloading .csv files and creating charts manually.",
    colour: "fuchsia",
  },
  {
    author: "Abdul",
    initials: "AN",
    company: "Kitchen & Home Seller",
    text: "The Shopdeploy software is easy to use, and the customer support is excellent!",
    colour: "orange",
  },

  {
    author: "Jaco",
    initials: "JB",
    company: "Electronics Seller",
    text: "The Shopdeploy team is always willing to assist. I've always had a great experience with their customer support",
    colour: "cyan",
  },
  {
    author: "Ernest",
    initials: "EM",
    company: "Health & Beauty Seller",
    text: "Only having to use a single app saves you money.",
    colour: "amber",
  },
  {
    author: "Ernest",
    initials: "EM",
    company: "Health & Beauty Seller",
    text: "Shopdeploy helps you make the right decisions about returns, sales, competition prices, and more. I wouldn't have been able to grow my business without it.",
    colour: "yellow",
  },
  {
    author: "Olivier",
    initials: "OC",
    company: "Garden & DIY Seller",
    text: "The Orders dashboard shows all the latest orders with VAT-included sales amount.",
    colour: "rose",
  },
  {
    author: "Abdul",
    initials: "AN",
    company: "Kitchen & Home Seller",
    text: "Shopdeploy's quick repricing feature changes prices very quickly, helping me win the Buy Box.",
    colour: "teal",
  },
  {
    author: "Bilal",
    initials: "BM",
    company: "Home & Appliances Seller",
    text: "Shopdeploy has helped us increase sales and optimise our Takealot Seller Business.",
    colour: "green",
  },
  {
    author: "Irfan",
    initials: "I",
    company: "Health & Beauty Seller",
    text: "Shopdeploy has made managing my Takealot Marketplace business much easier.",
    colour: "blue",
  },
  {
    author: "Jaco",
    initials: "JB",
    company: "Electronics Seller",
    text: "Working with Shopdeploy made everything so much easier.",
    colour: "red",
  },
];

function Testimonials() {
  let scrollRef = useRef(null);
  // Function to chunk the array
  const chunkArray = (array, size) => {
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  };

  const chunkedReviews = chunkArray(reviews, 2);

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollLeft += 1; // Adjust the value to change the scroll speed
      }
    }, 25); // Adjust the interval to control the smoothness of the scrolling

    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []);

  function sumWidthsByAriaHidden(target) {
    let totalWidth = 0;
    const elements = target.querySelectorAll('[aria-hidden="false"]'); // Selects all elements with aria-hidden="false"

    elements.forEach((element) => {
      totalWidth += element.offsetWidth;
    });

    return totalWidth;
  }

  const onScroll = (event) => {
    const scrollResetWidth = sumWidthsByAriaHidden(event.target);

    if (event.target.scrollLeft > scrollResetWidth) {
      event.target.scrollLeft = 1;
    }

    if (event.target.scrollLeft <= 0) {
      event.target.scrollLeft = scrollResetWidth;
    }
  };

  useEventListener("scroll", onScroll, scrollRef);

  const colorVariants = {
    blue: "bg-blue-200 text-blue-900 shadow-blue-100",
    red: "bg-red-200 text-red-900 shadow-red-100",
    teal: "bg-teal-200 text-teal-900 shadow-teal-100",
    rose: "bg-rose-200 text-rose-900 shadow-rose-100",
    cyan: "bg-cyan-200 text-cyan-900 shadow-cyan-100",
    green: "bg-green-200 text-green-900 shadow-green-100",
    fuchsia: "bg-fuchsia-200 text-fuchsia-900 shadow-fuchsia-100",
    pink: "bg-pink-200 text-pink-900 shadow-pink-100",
    purple: "bg-purple-200 text-purple-900 shadow-purple-100",
    orange: "bg-orange-200 text-orange-900 shadow-orange-100",
    lime: "bg-lime-200 text-lime-900 shadow-lime-100",
    yellow: "bg-yellow-200 text-yellow-900 shadow-yellow-100",
    amber: "bg-amber-200 text-amber-900 shadow-amber-100",
  };

  return (
    <div className=" bg-violet-800 md:py-24 py-12">
      <div className="pb-4 text-3xl md:text-6xl font-headline font-black text-white tracking-snug leading-10 md:leading-18 text-center mb-10 md:mb-20">
        Why our customers love us
      </div>
      <div>
        <div
          className="overflow-x-scroll flex will-change-scroll no-scrollbar"
          ref={scrollRef}
        >
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="flex flex-nowrap"
              aria-hidden={index === 0 ? false : true}
            >
              {chunkedReviews.map((pair, index_2) => (
                <div key={index_2}>
                  {pair.map((review, idx) => (
                    <div
                      key={idx}
                      className="p-6 border border-slate-800 mb-5 rounded-md bg-white w-80 sm:w-[512px] ml-5"
                    >
                      <div className="flex flex-row gap-2 align-middle justify-start items-center mb-4">
                        <div>
                          <div
                            className={`select-none flex h-8 w-8 sm:h-8 sm:w-8 p-1 mr-2 shrink-0 grow-0 items-center justify-center rounded-full outline-none shadow-[0_0_0_3px] ${
                              colorVariants[review.colour]
                            }`}
                          >
                            <span className="text-sm">{review.initials}</span>
                          </div>
                        </div>

                        <div className="text-sm leading-tight">
                          <div>{review.author}</div>
                          <div className="text-slate-600">{review.company}</div>
                        </div>
                      </div>

                      <div className="font-normal text-sm sm:leading-snug sm:text-lg antialiased text-slate-800">
                        {review.text}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { Testimonials };
